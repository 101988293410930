import React, { ReactElement, useMemo, useCallback, useState, useEffect } from 'react';

import { SettingsPanelProps } from '.';

import Panel from '../Panel';
import Select from '../Select';
import classes from './SettingsPanel.module.scss';

import { useSpring, animated } from 'react-spring';

export default (props: SettingsPanelProps): ReactElement<HTMLDivElement> => {
	
  const { state, dispatch } = props;
  const [isChanged, setIsChanged] = useState(false);

  const statusMessageSpring = useSpring({
    opacity: 1,
    config: { tension: 100, friction: 20 }
  });
  
  const unitsOptions = useMemo(() => {
    return [
      {
        value: 'metric',
        display: 'Metric (mm, N)',
      },
      {
        value: 'imperial',
        display: 'Imperial (in., lbf)',
      },
    ];
  }, []);

  const decimalSeparatorOptions = useMemo(() => {
    return [
      {
        value: '.',
        display: '. (point)',
      },
      {
        value: ',',
        display: ', (comma)',
      },
    ];
  }, []);

  const displayImageResultOptions = useMemo(() => {
    return [
      {
        value: 'true',
        display: 'Dimension in image',
      },
      {
        value: 'false',
        display: 'Dimensions in table',
      },
    ];
  }, []);

  const displayImageInputOptions = useMemo(() => {
    return [
      {
        value: 'true',
        display: 'Input using image',
      },
      {
        value: 'false',
        display: 'Input using form',
      },
    ];
  }, []);

  const includeResultsWithPartialDataOptions = useMemo(() => {
    return [
      {
        value: 'true',
        display: 'Include results with partial data',
      },
      {
        value: 'false',
        display: 'Omit results with partial data',
      },
    ];
  }, []);

  const onChangeHandler = useCallback((key: string, value: string) => {

    setIsChanged(true);

    dispatch({
      type: 'change',
      key,
      value,
    });
  }, [dispatch])

  // Reset changes if panel is opened or closed
  useEffect(() => {
    if (props.isOpen) {
      setIsChanged(false);
    }
  }, [props.isOpen]);


	return (
    <Panel {...props} title="Settings">
      {
        isChanged && (
          <animated.p role="status"
            style={statusMessageSpring}
            className={classes.Message}>
            The changes have been saved
          </animated.p>
        )
      }
      <Select label="Units"
        value={state.units}
        propsKey="units"
        handleChange={onChangeHandler}
        options={unitsOptions}
        id="units-select"
      />
      <Select label="Decimal Separator"
        value={state.decimalSeparator}
        propsKey="decimalSeparator"
        handleChange={onChangeHandler}
        options={decimalSeparatorOptions}
      />
      <Select label="Image Results"
        value={state.displayImageResult}
        propsKey="displayImageResult"
        handleChange={onChangeHandler}
        options={displayImageResultOptions}
      />
      <Select label="Image Input"
        value={state.displayImageInput}
        propsKey="displayImageInput"
        handleChange={onChangeHandler}
        options={displayImageInputOptions}
      />
      <Select label="Result Type"
        value={state.includeResultsWithPartialData}
        propsKey="includeResultsWithPartialData"
        handleChange={onChangeHandler}
        options={includeResultsWithPartialDataOptions}
      />
		</Panel>
	);

}
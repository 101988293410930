import React from 'react';
import { FrontPlateProps } from '.';

export default (props: FrontPlateProps) => {
	return (
		<g id="Front_Plate_Cropped">
			<rect x="0" y="5" width="12.7" height="15" fill="transparent" 	stroke="none" onClick={props.onClick} cursor={props.cursor || 'default'} />
			<g id="#g22614" pointerEvents="none">
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeOpacity="0.929"
					strokeWidth="0.3"
					d="M9.623 8.154c-1.154 1.478-1.79 3.348-1.792 5.281 0 1.932.631 3.802 1.783 5.282H29.48c1.154-1.478 1.79-3.348 1.793-5.281 0-1.932-.63-3.802-1.782-5.282zm3.63 3.051c2.966 0 2.966 4.45 0 4.45s-2.967-4.45 0-4.45zm12.7 0c2.967 0 2.967 4.45 0 4.45-2.966 0-2.966-4.45 0-4.45z"
				></path>
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeOpacity="0.929"
					strokeWidth="0.3"
					d="M22.323 8.154c-1.155 1.478-1.79 3.348-1.793 5.281 0 1.932.632 3.802 1.783 5.282H42.18c1.155-1.478 1.79-3.348 1.793-5.281 0-1.932-.63-3.802-1.781-5.282z"
				></path>
			</g>
			<g
				fill="#f00505"
				stroke="#ff0707"
				transform="rotate(90 34.242 -3.932)"
				pointerEvents="none"
			>
				<text
					x="-51.606"
					y="-28.226"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1)"
					pointerEvents="none"
				>
					<tspan className="text dimension">{props.height}</tspan>
				</text>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					d="M46.357 22.188v5"
				></path>
				<path
					fill="#df0c0c"
					stroke="#f31111"
					strokeWidth="0.3"
					d="M56.89 22.188v5"
				></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker3064)"
					markerStart="url(#marker3012)"
					d="M55.896 25.988h-8.535"
				></path>
			</g>
		</g>
	);
}
import React, { ReactElement, useMemo } from 'react';

import { FilterSummaryProps } from './';
import classes from './FilterSummary.module.scss';

export default (props: FilterSummaryProps): ReactElement<HTMLDivElement> => {
	
	const { numberEntries } = props;

	const chainWord = useMemo(() => {
		return numberEntries !== 1 ? 'chains' : 'chain';
	}, [numberEntries])

	return (
		<div className={classes.Root} role="status">
			{numberEntries} {chainWord} displayed
		</div>
	);

}
import React from 'react';
import { RightGeneralProps } from '.';

export default (props: RightGeneralProps) => {
	return (
		<g id="Right_General">
			<rect x="55" y="18" width="11" height="8" fill="transparent"
				stroke="none"
				onClick={props.onClickInsideWidth}
				cursor={props.cursor || 'default'}
			/>
			<rect x="47" y="0" width="11" height="8" fill="transparent"
				stroke="none"
				onClick={props.onClickOuterPlateThickness}
				cursor={props.cursor || 'default'}
			/>
			<rect x="60" y="0" width="11" height="8" fill="transparent"
				stroke="none"
				onClick={props.onClickInnerPlateThickness}
				cursor={props.cursor || 'default'}
			/>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M54.6 2.29H63.6V9.29H54.6z"
				transform="translate(1.122 7.433)"
				pointerEvents="none"
			></path>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M54.222 8.191H55.722V18.722H54.222z"
				pointerEvents="none"
			></path>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M51.321 0.759H52.821V11.290000000000001H51.321z"
				transform="translate(1.122 7.433)"
				pointerEvents="none"
			></path>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M64.722 8.145H66.222V18.676000000000002H64.722z"
				pointerEvents="none"
			></path>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M65.379 0.712H66.879V11.243H65.379z"
				transform="translate(1.122 7.433)"
				pointerEvents="none"
			></path>
			<g fill="#df0c0c" stroke="#f31111" transform="translate(9.942 -.465)"
				pointerEvents="none"
			>
				<text
					x="50.294"
					y="25.559"
					fill="red"
					stroke="#f90000"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
				>
					<tspan className="text dimension">{props.insideWidth}</tspan>
				</text>
				<path strokeWidth="0.3" d="M45.78 20.188v4M54.78 20.188v4"></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker4846)"
					markerStart="url(#marker4770)"
					d="M53.987 23.188h-7.365"
				></path>
			</g>
			<g
				fill="#df0c0c"
				stroke="#f31111"
				transform="rotate(180 59.247 13.955)"
				pointerEvents="none"
			>
				<text
					x="-53.023"
					y="-26.127"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1)"
				>
					<tspan className="text dimension">{props.innerPlateThickness}</tspan>
				</text>
				<path strokeWidth="0.3" d="M52.28 20.188v5M53.78 20.188v5"></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker17382)"
					markerStart="url(#marker17246)"
					d="M54.443 24.187h-2.84"
				></path>
			</g>
			<g
				fill="#df0c0c"
				stroke="#f31111"
				transform="rotate(180 53.122 13.955)"
				pointerEvents="none"
			>
				<text
					x="-53.023"
					y="-26.126"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1)"
				>
					<tspan className="text dimension">{props.outerPlateThickness}</tspan>
				</text>
				<path strokeWidth="0.3" d="M52.28 20.188v5M53.769 20.188v5"></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker18244)"
					markerStart="url(#marker18108)"
					d="M54.443 24.187h-2.84"
				></path>
			</g>
		</g>
	);
}
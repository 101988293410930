import React from 'react';
import { RightPinProps } from '.';

export default (props: RightPinProps) => {
	return (
		<g id="Right_Pin_Flat">
			<rect  x="50" y="26" width="20" height="7" fill="transparent"
				stroke="none"
				onClick={props.onClick}
				cursor={props.cursor || 'default'}
			/>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M55.121 11.211v.512h-3.398v3.443h3.398v.557h10.316v-.557h3.285v-3.443h-3.285v-.512z"
				pointerEvents="none"

			></path>
			<g fill="red" stroke="#f31111" transform="translate(9.892 4.535)"
				pointerEvents="none"
				>
				<text
					x="50.294"
					y="25.059"
					fill="#ff0707"
					stroke="#ff0707"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
				>
					<tspan className="text dimension">{props.length}</tspan>
				</text>
				<path
					strokeWidth="0.3"
					d="M41.88 12.188v11.531M58.78 12.188v11.531"
				></path>
				<path
					fill="#ff0707"
					stroke="#ff0707"
					strokeWidth="0.3"
					markerEnd="url(#marker11455)"
					markerStart="url(#marker11331)"
					d="M57.852 22.688h-15.01"
				></path>
			</g>
		</g>
	);
}
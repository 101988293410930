import React, { ReactElement } from 'react';

import { InfoPanelProps } from './';
import classes from './InfoPanel.module.scss';
import Panel from '../Panel';
import Icon from '../Icon';
import { ReactComponent as Filter } from '../../images/filter.svg';
import { ReactComponent as Tick } from '../../images/tick.svg';
import { ReactComponent as Settings } from '../../images/settings.svg';
import Button from '../Button';


export default (props: InfoPanelProps): ReactElement<HTMLDivElement> => {

	const iconProps = {
		fill: 'white',
		iconSize: '1rem',
		inline: true,
	};
	
	return (
		<Panel isOpen={props.isOpen} title="Information">
			<div className={classes.Content}>

				<div>
					Chain ID is your go to tool when you need to identify a chain by dimensions or breaking load specification.
						
					<h3>How to use</h3>
					<ol className={classes.Instructions}>
						<li>
							Select the filters panel <Icon icon={Filter} {...iconProps} /> from the top of the main page
						</li>
						<li>
							Input your measurements *
						</li>
						<li>
							The number of results will update as you type **
						</li>
						<li>
							On mobile, click the <Icon icon={Tick} {...iconProps}  /> at the top of the page or <Button inline={true} type="confirm">APPLY AND CLOSE</Button> at the bottom to see your results
						</li>
						<li>
							Input and results format (table/images) as well as other preferences can be defined in the settings page <Icon icon={Settings} {...iconProps} /> 
						</li>
					</ol>
					<div className={classes.InstructionNotes}>
						*  By default all measurements have a 'Medium' tolerance band (+/- margin of error around the value you enter)
						<br />
						** If you get too many or too few results, try altering the tolerance band
					</div>
				</div>
					
				<div className={classes.Disclaimer}>
					Direction to a particular chain type is made on the basis of the information provided by you. Whilst all reasonable care in compiling the information provided by you in this application is taken, no responsibility is accepted for errors. The application is intended to assist your choice and not advise in sourcing your preferred chain type. No representation or warranty is made.
						
					<p>
						Renold Chain ID v{process.env.REACT_APP_VERSION}, Copyright 2020 Renold PLC, all rights reserved
					</p>
				</div>
			</div>
		</Panel>
	);

}
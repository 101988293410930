import React from 'react';
import { ComponentSketchProps } from './';

export default (props: ComponentSketchProps) => {
	return (
		<g id="g1617" transform="translate(0 .316)">
			<g id="g1555">
				<path
					id="path1549"
					fill="none"
					stroke="#121212"
					strokeWidth="0.3"
					d="M25.902 11.211V3.723h-6.78v-5"
					markerStart="url(#edit-line-start)"
				></path>
			</g>
			<g id="g1570" stroke="#121212" strokeLinejoin="round" onClick={props.onClick} cursor="pointer">
				<circle
					id="path1551"
					cx="19.13"
					cy="-4.921"
					r="3.5"
					fill="#ebebeb"
					strokeWidth="0.3"
				></circle>
				<g
					id="g1563"
					strokeWidth="0.457"
					transform="rotate(39.769 6.36 34.036) scale(.65592)"
				>
					<path
						id="rect1557"
						d="M-13.878 -9.277H-12.878V-4.276999999999999H-13.878z"
					></path>
					<path
						id="path1559"
						d="M-13.378-2.663l-.5-.866h1l-.25.433z"
					></path>
				</g>
			</g>
		</g>
	);
}
import React from 'react';

export default () => {
	return (
		<g id="Front_Roller_Flanged">
			<g id="#g22229">
				<g>
					<ellipse
						cx="26.12"
						cy="13.437"
						fill="#ebebeb"
						stroke="#2a2a2a"
						strokeLinecap="round"
						strokeWidth="0.3"
						rx="6.739"
						ry="6.741"
						transform="skewX(-.93) scale(1 .99987)"
					></ellipse>
					<ellipse
						cx="26.12"
						cy="13.437"
						fill="#ebebeb"
						stroke="#2a2a2a"
						strokeLinecap="round"
						strokeWidth="0.3"
						rx="5.708"
						ry="5.71"
						transform="skewX(-.93) scale(1 .99987)"
					></ellipse>
				</g>
				<g transform="translate(-12.7)">
					<ellipse
						cx="26.12"
						cy="13.437"
						fill="#ebebeb"
						stroke="#2a2a2a"
						strokeLinecap="round"
						strokeWidth="0.3"
						rx="6.739"
						ry="6.741"
						transform="skewX(-.93) scale(1 .99987)"
					></ellipse>
					<ellipse
						cx="26.12"
						cy="13.437"
						fill="#ebebeb"
						stroke="#2a2a2a"
						strokeLinecap="round"
						strokeWidth="0.3"
						rx="5.708"
						ry="5.71"
						transform="skewX(-.93) scale(1 .99987)"
					></ellipse>
				</g>
			</g>
		</g>
	);
}
import React from 'react';
import { ComponentSketchProps } from '.';

export default (props: ComponentSketchProps) => {
	return (
		<g id="Front_Pin_Round" onClick={props.onClick}>
			<path
				fill="none"
				stroke="#000"
				strokeWidth="0.3"
				d="M13.273 10.174c-4.313.002-4.313 6.472 0 6.47 4.313-.002 4.313-6.472 0-6.47zM25.902 11.21a2.225 2.225 0 010 4.45 2.225 2.225 0 010-4.45z"
				className="component"
			></path>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeWidth="0.3"
				d="M38.602 11.21a2.225 2.225 0 010 4.45 2.225 2.225 0 010-4.45z"
				className="component"
			></path>
		</g>
	);
}
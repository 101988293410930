import React from 'react';
import { RightPinProps } from '.';

export default (props: RightPinProps) => {
	return (
		<g id="Right_Pin_Round">
			<rect x="50" y="26" width="20" height="7" fill="transparent"
				stroke="none"
				onClick={props.onClick}
				cursor={props.cursor || 'default'}
			/>
			<path
				fill="#ebebeb"
				stroke="#000"
				strokeLinecap="round"
				strokeWidth="0.3"
				d="M50.6 3.778H67.6V8.29H50.6z"
				transform="translate(1.122 7.433)"
				pointerEvents="none"
			></path>
			<g fill="red" stroke="#f31111" transform="translate(9.892 4.535)">
				<text
					x="50.294"
					y="25.059"
					fill="#ff0707"
					stroke="#ff0707"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
				pointerEvents="none"
				>
					<tspan className="text dimension">{props.length}</tspan>
				</text>
				<path
					strokeWidth="0.3"
					d="M41.88 12.188v11.531M58.78 12.188v11.531"
				></path>
				<path
					fill="#ff0707"
					stroke="#ff0707"
					strokeWidth="0.3"
					markerEnd="url(#marker11455)"
					markerStart="url(#marker11331)"
					d="M57.852 22.688h-15.01"
				></path>
			</g>
		</g>
	);
}
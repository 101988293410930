import React, { ReactElement } from 'react';

import { NavbarProps } from './';
import { ReactComponent as Logo } from '../../images/logo.svg';

import classes from './Navbar.module.scss';

export default (props: NavbarProps): ReactElement<HTMLDivElement> => {
	
	return (
		<div className={classes.Root}>
			<div className={classes.AppIdentity}>
				<Logo className={classes.Logo} />
				<div className={classes.AppName}>
					Chain ID
				</div>
			</div>
			<div className={classes.Icons}>
				{props.children}
			</div>
		</div>
	);

}
import React from 'react';
import { FrontPlateProps } from '.';

export default (props: FrontPlateProps) => {
	return (
		<g id="Front_Plate_Waisted" onClick={props.onClick}>
			<rect x="0" y="5" width="12.7" height="15" fill="transparent" stroke="none" onClick={props.onClick} cursor={props.cursor || 'default'} />
			<g id="#g22618"
					pointerEvents="none">
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeWidth="0.3"
					d="M7.852 13.436a5.25 5.25 0 006.513 5.095 21.558 21.558 0 0110.374 0 5.25 5.25 0 006.513-5.095A5.25 5.25 0 0024.74 8.34a21.558 21.558 0 01-10.374 0 5.25 5.25 0 00-6.513 5.096zm5.35-2.225a2.225 2.225 0 010 4.45 2.225 2.225 0 010-4.45m12.7 0a2.225 2.225 0 010 4.45 2.225 2.225 0 010-4.45"
					className="component"
				></path>
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeWidth="0.3"
					d="M20.552 13.436c0 3.41 3.202 5.916 6.513 5.095a21.558 21.558 0 0110.374 0c3.311.821 6.513-1.684 6.513-5.095 0-3.412-3.202-5.917-6.513-5.096a21.558 21.558 0 01-10.374 0c-3.31-.821-6.513 1.684-6.513 5.096z"
					className="component"
				></path>
			</g>
			<g
				fill="#f00505"
				stroke="#ff0707"
				transform="rotate(90 34.242 -3.932)"
				pointerEvents="none"
			>
				<text
					x="-51.606"
					y="-28.226"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1)"
				>
					<tspan className="text dimension">{props.height}</tspan>
				</text>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					d="M46.357 22.188v5"
				></path>
				<path
					fill="#df0c0c"
					stroke="#f31111"
					strokeWidth="0.3"
					d="M56.89 22.188v5"
				></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker3064)"
					markerStart="url(#marker3012)"
					d="M55.896 25.988h-8.535"
				></path>
			</g>
		</g>
	);
}
import FiltersPanel from './FiltersPanel';
import { InputsPanelProps } from '../InputsPanel';
import { FilterState, FilterAction } from '../../hooks/useFilter';
import { Units } from '../../hooks/usePreferences';

export interface FiltersPanelProps extends
	InputsPanelProps<FilterState, FilterAction> {
	resultCount: number;
	units: Units;
}

export default FiltersPanel;
import { HTMLProps } from 'react';

import ResultListCell from './ResultListCell';

export interface ResultListCellProps extends HTMLProps<HTMLDivElement> {
	label: string;
	value: string | string[];
	fullWidth?: boolean;
}

export default ResultListCell;
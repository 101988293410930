import React, { ReactElement } from 'react';

import { IconProps } from './';
import classes from './Icon.module.scss';

export default (props: IconProps): ReactElement<HTMLDivElement> => {

	const role = !!props.onClick ? 'button' : 'presentation';
	
	const rootClass = [classes.Root];

	if (props.margin === undefined || !!props.margin) {
		rootClass.push(classes.Margin);
	}

	if (!!props.inline) {
		rootClass.push(classes.Inline);
	}

	return (
		<div className={rootClass.join(' ')}
			onClick={props.onClick}
			data-testid={props['data-testid']}
			role={role}
		>
			<props.icon style={
					{
						fill: props.fill || 'inherit',
						width: props.iconSize || '26px',
						height: props.iconSize || '26px',
						cursor: !!props.onClick ? 'pointer' : 'default',
					}
				}
				role="img"
			/>
		</div>
	);

}
import React from 'react';
import { FrontGeneralProps } from '.';

export default (props: FrontGeneralProps) => {
	return (
		<g id="Front_General">
			<g
				fill="#df0c0c"
				stroke="#f31111"
				transform="translate(-32.774 -1.465)"
			>
				<rect x="45.7" y="20" width="12.7" height="12" fill="transparent" stroke="none" onClick={props.onClickPitchInner} cursor={props.cursor || 'default'} />
				<text
					x="52.222"
					y="26.558"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					pointerEvents="none"
				>
					<tspan className="text dimension">{props.pitchInner}</tspan>
				</text>
				<path strokeWidth="0.3" d="M45.98 21.188v4M58.68 21.188v4"></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker8906)"
					markerStart="url(#marker8806)"
					d="M57.682 24.088H46.896"
				></path>
			</g>
			<g fill="red" stroke="red" transform="translate(-19.874 -1.465)">
			<rect x="45.7" y="20" width="12.7" height="12" fill="transparent" stroke="none" onClick={props.onClickPitchOuter}  cursor={props.cursor || 'default'} />
				<text
					x="52.222"
					y="26.558"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					pointerEvents="none"
				>
					<tspan className="text dimension">{props.pitchOuter}</tspan>
				</text>
				<path strokeWidth="0.3" d="M45.78 21.188v4M58.48 21.188v4"></path>
				<path
					strokeWidth="0.3"
					markerEnd="url(#marker11141)"
					markerStart="url(#marker11029)"
					d="M57.508 24.088H46.709"
				></path>
			</g>
		</g>
	);
}
import React, { ReactElement, useState, useEffect, UIEvent, useCallback } from 'react';

import { ResultListProps } from './';
import classes from './ResultList.module.scss';
import ResultListEntry from '../ResultListEntry';

export default (props: ResultListProps): ReactElement<HTMLDivElement> => {

	const { entries } = props;

	const [
    numCatalogueEntriesDisplayed,
    setNumCatalogueEntriesDisplayed
  ] = useState(0);
  

  const handleScroll = useCallback((e: UIEvent<HTMLDivElement>) => {

    const { scrollHeight, scrollTop, clientHeight } = e.currentTarget;

    const bottomThreshold = 1;
    const atBottom = scrollHeight - scrollTop - clientHeight < bottomThreshold;

    if (atBottom && numCatalogueEntriesDisplayed < entries.length) {
      setNumCatalogueEntriesDisplayed(numCatalogueEntriesDisplayed + 10);
    }

  }, [
    numCatalogueEntriesDisplayed,
    setNumCatalogueEntriesDisplayed,
    entries,
  ]);

  useEffect(() => {
    setNumCatalogueEntriesDisplayed(Math.min(10, entries.length));
  }, [entries])
	
	return (
			<div className={classes.Root} onScroll={handleScroll}>
				<div className={classes.Results}>
					{
						props.entries
							.slice(0, numCatalogueEntriesDisplayed)
							.map((entry, index) => {
								return (
									<ResultListEntry
										entry={entry}
										key={`${entry.name}_${entry.description}_${index}`}
										displayImage={props.displayImage && !!entry.plateType}
									/>						
								)
							}
						)
					}
			</div>
		</div>
	);

}
import { HTMLProps, SVGProps, FunctionComponent } from 'react';

import Icon from './Icon';

export interface IconProps extends HTMLProps<HTMLDivElement> {
	icon: FunctionComponent<SVGProps<SVGSVGElement>>;
	fill?: string;
	onClick?: () => void;
	margin?: boolean;
	iconSize?: string;
	'data-testid'?: string;
	inline?: boolean;
}

export default Icon;
import React, { ReactElement, Children, useEffect, useState, useCallback } from 'react';

import { InputModalProps } from './';
import classes from './InputModal.module.scss';
import Button from '../Button';


export default (props: InputModalProps): ReactElement<HTMLDivElement> => {

	const { show, children, onClose, showProp, heading } = props;

	const [originalValue, setOriginalValue] = useState('');
	const [isChanged, setIsChanged] = useState(false);
	
	
	const match = Children.toArray(children).find(c => {
		if (React.isValidElement<HTMLDivElement>(c)) {
			for (let s of showProp || ['id']) {
				// const modalVariant = (c.props as any).variant;
				// const variantMatch = modalVariant === variant; 
				// console.log((c.props as any)[s], show, variant, modalVariant, variantMatch)
				if ((c.props as any)[s] === show) {
					return true;
				}
			}
		}
		return false;
	});

	useEffect(() => {
		if (!!match && !isChanged) {
			setOriginalValue((match as any).props.value);
			setIsChanged(true);
		}
	}, [match, isChanged]);

	const handleCancelChange = useCallback(() => {
		if (!!match) {
			if (React.isValidElement<HTMLDivElement>(match)) {
				(match.props as any).handleChange(show, originalValue);
			}
		}
		if (onClose) {
			onClose();
		}
	}, [match, onClose, show, originalValue]);
	
	return (
		<div className={classes.Root}>
			<div className={classes.Content}>
				<div className={classes.Heading}>
					{!!heading && <h3 className={classes.Title}>{heading}</h3>}
				</div>
				<div className={classes.Match}>
					{match}
				</div>
				<div className={classes.ActionsContainer}>
					<div className={classes.Actions}>
						<Button onClick={handleCancelChange} type="cancel">
							CANCEL
						</Button>
						<Button onClick={onClose} type="confirm">
							APPLY
						</Button>
					</div>
				</div>
			</div>
		</div>
	);

}
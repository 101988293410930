import React, { ReactElement } from 'react';

import { NavbarIconsProps } from './';
import classes from './NavbarIcons.module.scss';

import { ReactComponent as Info } from '../../images/info.svg';
import { ReactComponent as Filter } from '../../images/filter.svg';
import { ReactComponent as Tick } from '../../images/tick.svg';
import { ReactComponent as Settings } from '../../images/settings.svg';
import Icon from '../Icon';

export default (props: NavbarIconsProps): ReactElement<HTMLDivElement> => {
	
	const { openPanel, setOpenPanel } = props;
	const panelIconClass = [classes.OpenPanelIcons];
	const closePanelClass = [classes.ClosePanelIcon];

	if (!!openPanel) {
		panelIconClass.push(classes.PanelOpen);
		closePanelClass.push(classes.PanelOpen);
	}

	return (
		<>
			
			<div className={closePanelClass.join(' ')}>
				<Icon icon={Tick} fill="white"
					data-testid="close"
					onClick={() => {
						setOpenPanel('');
					}}
				/>
			</div>
			<div className={panelIconClass.join(' ')}>
				<Icon icon={Info} fill="white"
					data-testid="info"
					onClick={() => setOpenPanel('info')}
				/>
				<Icon icon={Settings} fill="white"
					data-testid="settings"
					onClick={() => setOpenPanel('settings')}
				/>
				<Icon icon={Filter} fill="white"
					data-testid="filter"
					onClick={() => setOpenPanel('filter')}
				/>
			</div>

		</>
	);

}
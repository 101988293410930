import React, { useState } from 'react';

import classes from './App.module.scss';
import Navbar from '../Navbar';



import useCatalogue from '../../hooks/useCatalogue';
import ResultList from '../ResultList';
import FiltersPanel from '../FiltersPanel';
import usePreferences from '../../hooks/usePreferences';
import SettingsPanel from '../SettingsPanel';
import InfoPanel from '../InfoPanel';
import FilterSummary from '../FilterSummary';
import FiltersPanelSketch from '../FiltersPanelSketch/FiltersPanelSketch';
import NavbarIcons from '../NavbarIcons';

export default () => {

  const [
    preferencesState,
    preferencesDispatch,
  ] = usePreferences();

  const [
    filteredDisplayCatalogue,
    filter,
    filterDispatch,
  ] = useCatalogue(preferencesState);

  const isSmallScreen = window.innerWidth < 800;
  const hasStoredPreferences = !!window.localStorage.getItem('preferences');
  const startPanel = hasStoredPreferences ? 'filter' : 'info';

  const [openPanel, setOpenPanel] =
    useState<string>(isSmallScreen ? '' : startPanel);

  return (
    <div className={classes.Root}>
      <Navbar>
        <NavbarIcons
          openPanel={openPanel}
          setOpenPanel={setOpenPanel}
        />        
      </Navbar>
      <div className={classes.Content}>
        <div className={classes.Data}>
          <div className={classes.Instructions}>
            Use the filter menu to narrow down results
          </div>
          <FilterSummary numberEntries={filteredDisplayCatalogue.length} />
          <ResultList entries={filteredDisplayCatalogue}
            displayImage={preferencesState.displayImageResult === 'true'}
          />
        </div>
        <div className={classes.Panels}>
          {
            preferencesState.displayImageInput === 'true' ? (
              <FiltersPanelSketch state={filter}
                dispatch={filterDispatch}
                isOpen={openPanel === 'filter'}
                resultCount={filteredDisplayCatalogue.length}
                units={preferencesState.units}
                onClose={() => setOpenPanel('')}
              />        
            ) : (
              <FiltersPanel state={filter}
                dispatch={filterDispatch}
                isOpen={openPanel === 'filter'}
                resultCount={filteredDisplayCatalogue.length}
                units={preferencesState.units}
                onClose={() => setOpenPanel('')}  
              />
            )
          }
          <SettingsPanel state={preferencesState}
            dispatch={preferencesDispatch}
            isOpen={openPanel === 'settings'}
            resultCount={filteredDisplayCatalogue.length}
            onClose={() => setOpenPanel('')}
          />
          <InfoPanel isOpen={openPanel === 'info'} />
        </div>
      </div>
    </div>
  );
}


import React from 'react';
import { RightRollerProps } from '.';

export default (props: RightRollerProps) => {
	return (
		<g id="Right_Roller_Flanged">
			<g id="#g22208">
				<rect x="63" y="7.3" width="12.7" height="12" fill="transparent" stroke="none" onClick={props.onClick} cursor={props.cursor || 'default'}
				/>
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeWidth="0.3"
					d="M56.191 7.723H64.285V19.142H56.191z"
					pointerEvents="none"
				></path>
				<g
					fill="#df0c0c"
					stroke="#f31111"
					transform="matrix(0 1 1 0 49.812 -38.562)"
					pointerEvents="none"
				>
					<text
						x="-52.077"
						y="24.472"
						fill="#f31111"
						strokeWidth="0"
						dominantBaseline="middle"
						fontFamily="Encode Sans Condensed"
						fontSize="2.3"
						textAnchor="middle"
						transform="scale(-1 1)"
					>
						<tspan className="text dimension">{props.diameter}</tspan>
					</text>
					<path
						strokeWidth="0.3"
						d="M46.285 15.31v8M57.704 15.31v8"
					></path>
					<path
						fill="#f31111"
						strokeWidth="0.3"
						markerEnd="url(#marker19612)"
						markerStart="url(#marker19452)"
						d="M56.715 22.188h-9.44"
					></path>
					<path
						fill="#ebebeb"
						stroke="#000"
						strokeWidth="0.3"
						d="M45.285 6.31h13.526v1l-1.08.529H46.278l-.993-.53z"
					></path>
				</g>
			</g>
		</g>
	);
}
import React from 'react';
import { ComponentSketchProps } from '.';

export default (props: ComponentSketchProps) => {
	return (
		<g id="g1625" transform="translate(0 .316)">
			<path
				id="path1572"
				fill="none"
				stroke="#000"
				strokeWidth="0.3"
				d="M32.122 9v-10.2"
				markerStart="url(#edit-line-start)"
			></path>
			<g
				id="g1608"
				stroke="#121212"
				strokeLinejoin="round"
				transform="translate(12.891 .098)"
				onClick={props.onClick} cursor="pointer"
			>
				<circle
					id="circle1600"
					cx="19.13"
					cy="-4.921"
					r="3.5"
					fill="#ebebeb"
					strokeWidth="0.3"
				></circle>
				<g
					id="g1606"
					strokeWidth="0.457"
					transform="rotate(39.769 6.36 34.036) scale(.65592)"
				>
					<path
						id="rect1602"
						d="M-13.878 -9.277H-12.878V-4.276999999999999H-13.878z"
					></path>
					<path
						id="path1604"
						d="M-13.378-2.663l-.5-.866h1l-.25.433z"
					></path>
				</g>
			</g>
		</g>
	);
}
import React, { ReactElement, useMemo, useCallback, useState, useEffect } from 'react';

import { FiltersPanelSketchProps } from './';
import classes from './FiltersPanelSketch.module.scss';
import Button from '../Button';
import Icon from '../Icon';
import FilterInput from '../FilterInput';

import { ReactComponent as Clear } from '../../images/close.svg';
import Panel from '../Panel';
import Select from '../Select';
import {
  plateTypeOptions,
  rollerTypeOptions,
  pinTypeOptions,
  accuracyOptions,
  chainTypeOptions,
} from '../../hooks/useFilter';
import { getLengthUnits, getForceUnits } from '../../hooks/usePreferences';
import InputModal from '../InputModal';
import InputSketch from '../Sketch/InputSketch';


export default (props: FiltersPanelSketchProps): ReactElement<HTMLDivElement> => {
  
  
  const { state, dispatch, isOpen, resultCount, units } = props;

  const [openInput, setOpenInput] = useState<string>('');
  const [modalHeading, setModalHeading] = useState<string>('');

  
  const lengthUnits = useMemo(() => getLengthUnits(units), [units]);
  const forceUnits = useMemo(() => getForceUnits(units), [units]);

  const handleFilterChange = useCallback((key: string, value: string) => {
    dispatch({
			type: 'filter',
			key,
			value,
		});
  }, [dispatch]);
  
  const handleFilterClear = useCallback((key: string) => {
    dispatch({
			type: 'clear',
			key,
		});
  }, [dispatch]);

  const handleOpenModal = useCallback((key: string, heading?: string) => {
    setOpenInput(key);
    setModalHeading(heading || '');
  }, []);

  useEffect(() => {
    setOpenInput('');
  }, [isOpen]);

  return (
    <>
      
    
      <Panel {...props} title="Filters">
        <div className={classes.Count}>
          {resultCount} results found
        </div>
        <Button onClick={() => dispatch({ type: 'clearAll' })}>
          <span className={classes.Label}>Clear all filters</span><Icon icon={Clear} margin={false} iconSize="12px" />
        </Button>
        <Select label="Search Accuracy"
          value={state.accuracy.value}
          propsKey="accuracy"
          handleChange={handleFilterChange}
          options={accuracyOptions}
        />
        <FilterInput label={`Tensile Strength (${forceUnits})`}
          value={state.tensileStrengthNewtons.value}
          filterKey="tensileStrengthNewtons"
          handleChange={handleFilterChange}
          handleClear={handleFilterClear}
        />
        <Select label="Chain Type"
          value={state.chainType.value}
          propsKey="chainType"
          handleChange={handleFilterChange}
          options={chainTypeOptions}
        />
        <FilterInput label={`Pin Outer Diameter`}
          value={state.pinOuterDiameter.value}
          filterKey="pinOuterDiameter"
          handleChange={handleFilterChange}
          handleClear={handleFilterClear}
        />
        
        <div className={classes.SketchContainer}>
          <div className={classes.SketchTitle}>
            Dimension and Component Filters
          </div>
          <InputSketch state={state}
            // pitches={pitches}
            openModal={handleOpenModal}
            units={lengthUnits}
          />
        </div>
        
        
      </Panel>
      {
        !!openInput && (
          <InputModal show={openInput} showProp={['filterKey', 'propsKey']} onClose={() => handleOpenModal('')} heading={modalHeading}>
            <FilterInput
              value={state.pitchInner.value}
              filterKey="pitchInner"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.pitchOuter.value}
              filterKey="pitchOuter"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <Select
              value={state.plateType.value}
              propsKey="plateType"
              handleChange={handleFilterChange}
              options={plateTypeOptions}
            />
            <Select
              value={state.rollerType.value}
              propsKey="rollerType"
              handleChange={handleFilterChange}
              options={rollerTypeOptions}
            />
            <Select
              value={state.pinType.value}
              propsKey="pinType"
              handleChange={handleFilterChange}
              options={pinTypeOptions}
            />
            <FilterInput
              value={state.insideWidth.value}
              filterKey="insideWidth"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.rollerOuterDiameter.value}
              filterKey="rollerOuterDiameter"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.pinLength.value}
              filterKey="pinLength"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.innerPlateThickness.value}
              filterKey="innerPlateThickness"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.outerPlateThickness.value}
              filterKey="outerPlateThickness"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.plateHeight.value}
              filterKey="plateHeight"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
            <FilterInput
              value={state.tensileStrengthNewtons.value}
              filterKey="tensileStrengthNewtons"
              handleChange={handleFilterChange}
              handleClear={handleFilterClear}
            />
          </InputModal>
        )
      }
    </>  
  );

}
import { HTMLProps } from 'react';

import Select from './Select';

export type SelectOption = {
	value: string | number;
	display: string;
};

export interface SelectProps extends HTMLProps<HTMLSelectElement> {
	propsKey: string;
	options: SelectOption[];
	label?: string;
	handleChange: (key: string, value: string) => void;
	// dispatch: Dispatch<any>;
}

export default Select;
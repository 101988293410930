import { useReducer, useMemo, Dispatch } from 'react';

export type Units = 'metric' | 'imperial';
export type DecimalSeparator = ',' | '.';
export type BooleanString = 'true' | 'false';
type PreferenceValue = string | number | Units | DecimalSeparator | boolean;

export interface PreferencesState {
	[key: string]: PreferenceValue;
	units: Units;
	decimalSeparator: DecimalSeparator;
	displayImageResult: BooleanString;
	displayImageInput: BooleanString;
	includeResultsWithPartialData: BooleanString;
}

export type PreferencesAction =
	{ type: 'change', key: string, value: string } |
	{ type: 'load' } |
	{ type: 'reset' };

export default (): [
	PreferencesState,
	Dispatch<PreferencesAction>,
] => {

	const preferences = useMemo(() => {
		const storedPreferences = window.localStorage.getItem('preferences');
	
		const preferences = !!storedPreferences ? {
			...defaultState,
			...JSON.parse(storedPreferences),
		} : defaultState;

		setStoredPreferences(preferences);

		return preferences;
		
	}, []);

	
	return useReducer(reducer, preferences);

}

const defaultState: PreferencesState = {
	units: 'metric',
	decimalSeparator: '.',
	displayImageResult: 'true',
	displayImageInput: 'false',
	includeResultsWithPartialData: 'false',
};

export const getLengthUnits =
	(units: Units) => units === 'metric' ? 'mm' : 'in.';
export const getForceUnits =
	(units: Units) => units === 'metric' ? 'N' : 'lbf.';

function reducer(
	state: PreferencesState, action: PreferencesAction
): PreferencesState {
	switch (action.type) {
		case 'change': return change(state, action);
		default: return state;
	};
}

function setStoredPreferences(preferences: PreferencesState): void {
	window.localStorage.setItem('preferences', JSON.stringify(preferences));
}

function change(state: PreferencesState, action: PreferencesAction): PreferencesState {
	const newState = { ...state };

	if (action.type !== "change") {
		return state;
	}

	const returnValue = {
		...newState,
		[action.key]: action.value,
	};

	setStoredPreferences(returnValue);

	return returnValue;
}

import { HTMLProps } from 'react';

import Button from './Button';
import { IconProps } from '../Icon';

export interface ButtonProps extends HTMLProps<HTMLButtonElement> {
	icon?: IconProps;
	type?: 'default' | 'confirm' | 'cancel';
	inline?: boolean;
}

export default Button;
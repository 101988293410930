import { useReducer } from 'react';

export const plateTypeOptions = [
	{
		value: 'Any',
		display: 'Any',
	},
	{
		value: 'straight',
		display: 'Straight Sided',
	},
	{
		value: 'waisted',
		display: 'Waisted',
	},
	{
		value: 'cropped',
		display: 'Cropped',
	},
];

export const rollerTypeOptions = [
	{
		value: 'Any',
		display: 'Any',
	},
	{
		value: 'Plain',
		display: 'Plain',
	},
	{
		value: 'Flanged',
		display: 'Flanged',
	},
	{
		value: 'None',
		display: 'No roller',
	},
];

export const pinTypeOptions = [
	{
		value: 'Any',
		display: 'Any',
	},
	{
		value: 'Solid',
		display: 'Solid',
	},
	{
		value: 'Solid_Flat',
		display: 'Solid and Flatted',
	},
	{
		value: 'Hollow',
		display: 'Hollow',
	},
];

export const accuracyOptions = [
	{
		value: '0',
		display: 'Exact',
	},
	{
		value: '0.02',
		display: 'Fine',
	},
	{
		value: '0.04',
		display: 'Medium',
	},
	{
		value: '0.06',
		display: 'Rough',
	},
];

export const chainTypeOptions = [
	{
		value: 'any',
		display: 'Any',
	},
	{
		value: 'cvc',
		display: 'Conveyor',
	},
	{
		value: 'engineering_class',
		display: 'Engineering Class',
	},
	{
		value: 'transmission',
		display: 'Transmission',
	},
];

export type FilterType = 'string' | 'number' | 'number[]';

export type FilterValue = {
	type: FilterType;
	value: string;
}

export interface FilterState {
	[key: string]: FilterValue;
	innerPlateThickness: FilterValue;
	outerPlateThickness: FilterValue;
	insideWidth: FilterValue;
	name: FilterValue;
	pinLength: FilterValue;
	pinOuterDiameter: FilterValue;
	pitch: FilterValue;
	pitchInner: FilterValue;
	pitchOuter: FilterValue;
	plateHeight: FilterValue;
	rollerOuterDiameter: FilterValue;
	tensileStrengthNewtons: FilterValue;
	waistRatio: FilterValue;
	plateType: FilterValue;
	rollerType: FilterValue;
	pinType: FilterValue;
	chainType: FilterValue;
}

export type FilterAction =
	{ type: 'filter', key: string, value: string } |
	{ type: 'clearAll' } |
	{ type: 'clear', key: string };

export default () => {
	return useReducer(reducer, defaultState);

}

const defaultState: FilterState = {
	innerPlateThickness: {
		type: 'number',
		value: '',
	},
	outerPlateThickness: {
		type: 'number',
		value: '',
	},
	insideWidth: {
		type: 'number',
		value: '',
	},
	name: {
		type: 'string',
		value: '',
	},
	pinOuterDiameter: {
		type: 'number',
		value: '',
	},
	pinLength: {
		type: 'number',
		value: '',
	},
	pitch: {
		type: 'number[]',
		value: '',
	},
	pitchInner: {
		type: 'number[]',
		value: '',
	},
	pitchOuter: {
		type: 'number[]',
		value: '',
	},
	plateHeight: {
		type: 'number',
		value: '',
	},
	rollerOuterDiameter: {
		type: 'number',
		value: '',
	},
	tensileStrengthNewtons: {
		type: 'number',
		value: '',
	},
	waistRatio: {
		type: 'number',
		value: '',
	},
	accuracy: {
		type: 'number',
		value: '0.04',
	},
	plateType: {
		type: 'string',
		value: 'Any',
	},
	rollerType: {
		type: 'string',
		value: 'Any',
	},
	pinType: {
		type: 'string',
		value: 'Any',
	},
	chainType: {
		type: 'string',
		value: 'Any',
	},
};

function reducer(state: FilterState, action: FilterAction): FilterState {
	switch (action.type) {
		case 'filter': return filter(state, action);
		case 'clear': return filter(state, action);
		case 'clearAll': return defaultState;
		default: return state;
	};
}

function filter(state: FilterState, action: FilterAction): FilterState {
	const newState = { ...state };

	if (action.type !== 'filter' && action.type !== 'clear') {
		return state;
	}

	let value = action.type === 'clear'
		? '' :
		cleanDecimalSeparators(action.value, state[action.key].value);

	
	return {
		...newState,
		[action.key]: {
			type: newState[action.key].type,
			value,
		}
	};
}

function cleanDecimalSeparators(value: string, curValue: string): string {

	const regex = /[.,]/g;
	const match = value.match(regex);

	const count = !!match ? match.length : 0;

	// If user is trying to add a second decimal separator, block the change
	return count > 1 ? curValue : value;
}

import React from 'react';
import { RightRollerProps } from '.';

export default (props: RightRollerProps) => {
	return (
		<g id="Right_Roller_None">
			<rect x="63" y="7" width="12.7" height="12" fill="transparent" stroke="none" onClick={props.onClick} cursor={props.cursor || 'default'} />
			<g
				id="#g18420"
				fill="#df0c0c"
				stroke="#f31111"
				display="inline"
				transform="matrix(0 1 1 0 49.812 -38.562)"
				pointerEvents="none"
			>	
				<text
					x="-51.615"
					y="24.472"
					fill="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1 1)"
					pointerEvents="none"
				>
					<tspan className="text dimension">{props.diameter}</tspan>
				</text>
				<path strokeWidth="0.3" d="M48.28 15.31v8M55.28 15.31v8"></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker18574)"
					markerStart="url(#marker18426)"
					d="M54.372 22.188h-5.234"
				></path>
			</g>
		</g>
	);
}
import React, { ReactElement, useMemo } from 'react';

import { ResultListCellProps } from './';
import classes from './ResultListCell.module.scss';

export default (props: ResultListCellProps): ReactElement<HTMLDivElement> => {
	
	const { value } = props;

	const rootClass = [classes.Root];
	if (!!props.fullWidth) {
		rootClass.push(classes.FullWidth)
	}

	const displayValue = useMemo(() => {

		if (Array.isArray(value)) {
			return value.map((val, index) => {
				const comma = index < value.length - 1 ? ', ' : '';
				return (
					<span className={classes.ArrayValue} key={val + index}>
						{`${val}${comma}`}
					</span>
				);
			});
		} else {
			return value;
		}

	}, [value]);

	return (
		<div className={rootClass.join(' ')}>
			<div className={classes.Label}>{props.label}</div>
			<div className={classes.Value}>{displayValue}</div>
		</div>
	);

}
import React from 'react';
import { ComponentSketchProps } from '.';

export default (props: ComponentSketchProps) => {
	return (
		<g id="Front_Pin_Flat" onClick={props.onClick}>
			<g id="#g22623">
				<path
					fill="none"
					stroke="#121212"
					strokeLinecap="round"
					strokeWidth="0.3"
					d="M24.49 11.723a2.227 2.221 0 00-.803 1.527v.373a2.227 2.221 0 00.824 1.543h2.791a2.227 2.221 0 00.819-1.51v-.43a2.227 2.221 0 00-.799-1.503zM37.177 11.723a2.227 2.221 0 00-.803 1.527v.373a2.227 2.221 0 00.824 1.543h2.791a2.227 2.221 0 00.819-1.51v-.43a2.227 2.221 0 00-.799-1.503zM11.2 10.723a3.24 3.447 0 00-1.168 2.37v.578a3.24 3.447 0 001.2 2.394h4.06a3.24 3.447 0 001.19-2.342v-.667a3.24 3.447 0 00-1.162-2.333z"
				></path>
			</g>
		</g>
	);
}
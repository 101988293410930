import React from 'react';

export default () => {
	return (
		<g id="Front_Roller_Large">
			<g id="#g22221">
				<circle
					cx="13.173"
					cy="13.409"
					r="6.738"
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeOpacity="0.929"
					strokeWidth="0.3"
				></circle>
				<circle
					cx="25.902"
					cy="13.436"
					r="6.738"
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeOpacity="0.929"
					strokeWidth="0.3"
				></circle>
			</g>
		</g>
	);
}
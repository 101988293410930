import React, { ReactElement, useMemo } from 'react';

import { InputSketchProps } from '.';
import classes from './InputSketch.module.scss';

import FrontPlateCropped from '../Parts/FrontPlateCropped';
import FrontRollerFlanged from '../Parts/FrontRollerFlanged';
import FrontPlateWaisted from '../Parts/FrontPlateWaisted';
import FrontGeneral from '../Parts/FrontGeneral';
import FrontPinRound from '../Parts/FrontPinRound';
import FrontPinFlat from '../Parts/FrontPinFlat';
import RightPinFlat from '../Parts/RightPinFlat';
import RightPinRound from '../Parts/RightPinRound';
import RightGeneral from '../Parts/RightGeneral';
import RightRollerFlanged from '../Parts/RightRollerFlanged';
import RightRollerNone from '../Parts/RightRollerNone';
import RightRollerStandard from '../Parts/RightRollerStandard';
import FrontPlateStraight from '../Parts/FrontPlateStraight';

import EditPinTypeIcon from '../Parts/EditPinTypeIcon';
import EditPlateTypeIcon from '../Parts/EditPlateTypeIcon';
import EditRollerTypeIcon from '../Parts/EditRollerTypeIcon';

export default (props: InputSketchProps): ReactElement<SVGSVGElement> => {

	const {
    state,
    openModal,
    units,
	} = props;

	const {
		innerPlateThickness,
		insideWidth,
		outerPlateThickness,
		pinLength,
		plateHeight,
		rollerOuterDiameter,
    pinType,
    rollerType,
    plateType,
    pitchInner,
    pitchOuter,
  } = state;

	const flats = pinType.value.indexOf('Flat') > -1;
  const flangedRoller = rollerType.value === 'Flanged';
	// const largeRoller = name.indexOf('LARGE') > -1;
  const bushChain = rollerType.value === 'None';
	
	
  const frontPlate = useMemo(() => {
     
    const croppedPlate = plateType.value === 'CVC';
    const straightPlate = plateType.value.indexOf('Straight') > -1;

		if (croppedPlate) {
      return <FrontPlateCropped
        height={plateHeight.value as string || 'Add Filter'}
        onClick={() => openModal('plateHeight', `Plate Height (${units})`)}
        cursor="pointer"
      />
		} else {
			return !straightPlate ?
        <FrontPlateWaisted
          height={plateHeight.value as string || 'Add Filter'}
          onClick={() => openModal('plateHeight', `Plate Height (${units})`)}
          cursor="pointer"
        /> :
        <FrontPlateStraight
        height={plateHeight.value as string || 'Add Filter'}
        onClick={() => openModal('plateHeight', `Plate Height (${units})`)}
        cursor="pointer"
      />
		}
	}, [plateHeight, plateType, units, openModal]);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -15 75.93 46.555"
      className={classes.View}
      width="100%"
      height="100%"
		>
      <defs>
        <marker id="marker19612" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker19452" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18574" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker18426" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18244" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18108" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker17382" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker17246" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker15525" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker15401" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker11455" orient="auto" overflow="visible">
          <path
            fill="#ff0707"
            fillRule="evenodd"
            stroke="#ff0707"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker11331" orient="auto" overflow="visible">
          <path
            fill="#ff0707"
            fillRule="evenodd"
            stroke="#ff0707"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker11141" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker11029" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker10336" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker10236" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker8906" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker8806" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker4846" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker4770" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker3064" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker3012" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="edit-line-start" orient="auto" overflow="visible">
          <path
            fill="black"
            fillRule="evenodd"
            stroke="black"
            strokeWidth=".4pt"
            d="M0 0l3.46-2v4z"
          ></path>
        </marker>
      </defs>
      
      <g id="Front" transform="translate(0 .316)">
        <g id="Front_Roller">
					{flangedRoller && <FrontRollerFlanged />}
					{/* {largeRoller && <FrontRollerLarge />} */}
        </g>
				<g id="Front_Plate">
					
					{frontPlate}
					
        </g>
        
        <FrontGeneral
          pitchInner={pitchInner.value || 'Add Filter'}
          onClickPitchInner={() => openModal('pitchInner', `Pitch Inner (${units})`)}
          pitchOuter={pitchOuter.value || 'Add Filter'}
          onClickPitchOuter={() => openModal('pitchOuter', `Pitch Outer (${units})`)}
          cursor="pointer"
        />
				
        <g id="Front_Pin">
          {flats ?
            <FrontPinFlat
              onClick={() => openModal('pinType', 'Pin Type')}
            /> : <FrontPinRound
              onClick={() => openModal('pinType', 'Pin Type')}
            />
          }
        </g>
        <g id="Front_Axes">
          <g id="#g22628">
            <path
              fill="#ebebeb"
              stroke="#000"
              strokeDasharray="1.20000005, 0.60000002, 0.30000001, 0.60000002"
              strokeWidth="0.3"
              d="M25.902 9.211v8.45m-4.225-4.225h8.45M13.202 9.21v8.45m-4.225-4.224h8.45M38.602 9.211v8.45m-4.225-4.225h8.45"
              className="axis"
            ></path>
          </g>
        </g>
      </g>
      <g id="Right" transform="translate(0 .316)">
        <g id="Right_Pin">
					{flats && <RightPinFlat length={pinLength.value as string || 'Add Filter'} onClick={
            () => openModal('pinLength', `Pin Length (${units})`)
          }/>}
					{!flats && <RightPinRound length={pinLength.value as string || 'Add Filter'}onClick={
            () => openModal('pinLength', `Pin Length (${units})`)
          } />}
        </g>
				<RightGeneral
					innerPlateThickness={innerPlateThickness.value as string || 'Add Filter'}
					outerPlateThickness={outerPlateThickness.value as string || 'Add Filter'}
          insideWidth={insideWidth.value as string || 'Add Filter'}
          onClickInsideWidth={
            () => openModal('insideWidth', `Inside Width (${units})`)
          }
          onClickInnerPlateThickness={
            () => openModal('innerPlateThickness', `Inner Plate Thickness (${units})`)
          }
          onClickOuterPlateThickness={
            () => openModal('outerPlateThickness', `Outer Plate Thickness (${units})`)
          }
				/>
        <g id="Right_Roller">
					{flangedRoller &&
            <RightRollerFlanged diameter={rollerOuterDiameter.value as string || 'Add filter'}
              onClick={() => openModal('rollerOuterDiameter', `Roller / Bush Dia. (${units})`)} cursor="pointer" />
          }
					{/* {largeRoller &&
						<RightRollerLarge diameter={rollerOuterDiameter} />} */}
          {
            bushChain &&
						<RightRollerNone diameter={rollerOuterDiameter.value as string || 'Add filter'}
            onClick={() => openModal('rollerOuterDiameter', `Roller / Bush Dia. (${units})`)} cursor="pointer" />
          }
          {
            !flangedRoller && !bushChain &&
						<RightRollerStandard diameter={rollerOuterDiameter.value as string || 'Add filter'}
            onClick={() => openModal('rollerOuterDiameter', `Roller / Bush Dia. (${units})`)} cursor="pointer" />
          }
        </g>
        <g id="Right_Axes">
          <path
            fill="#ebebeb"
            stroke="#000"
            strokeDasharray="1.20000005, 0.60000002, 0.30000001, 0.60000002"
            strokeWidth="0.3"
            d="M60.238 5.944v15m-9.5-7.5h19"
            className="axis"
          ></path>
        </g>
      </g>
      <EditPlateTypeIcon
        onClick={() => openModal('plateType', 'Plate Type')}
      />
      <EditPinTypeIcon
        onClick={() => openModal('pinType', 'Pin Type')}
      />
      <EditRollerTypeIcon
        onClick={() => openModal('rollerType', 'Roller Type')}
      />
    </svg>
	);

}

import React, { ReactElement, useMemo } from 'react';

import { ResultListEntryProps } from './';
import classes from './ResultListEntry.module.scss';
import ResultListCell from '../ResultListCell';
import Sketch from '../Sketch';

export default (props: ResultListEntryProps): ReactElement<HTMLDivElement> => {
	
	const { entry, displayImage } = props;

	const sketch = useMemo(() => {
		return displayImage ? (
			<div className={classes.SketchContainer}>
				<Sketch dimensions={entry} />
			</div>
		) : null;
	}, [entry, displayImage]);


	return (
		<>
			<div className={classes.Root}>
				<div className={classes.Table}>

					<ResultListCell label="Name" value={`${entry.name} ${entry.description}`} fullWidth={true} />
					
					<div className={!props.displayImage ? classes.TableGrid : ''}>
						<ResultListCell
							label="Pitch Inner"
							value={entry.pitchInner}
						/>
						{
							!entry.pitchInner.every((p, index) => p === entry.pitchOuter[index]) ?
							<ResultListCell
								label="Pitch Outer"
								value={entry.pitchOuter}
							/> : 
							<ResultListCell
								label="Pitch Outer"
								value="Same as Pitch Inner"
							/>
						}
						<ResultListCell
							label="Tensile Strength"
							value={entry.tensileStrengthNewtons}
						/>
						<ResultListCell
							label="Pin Outer Diameter"
							value={entry.pinOuterDiameter}
						/>
					</div>

					
					{!props.displayImage && (
						<div className={classes.TableGrid}>
							<ResultListCell
								label="Pin Length"
								value={entry.pinLength}
							/>
							<ResultListCell
								label="Inside Width"
								value={entry.insideWidth}
							/>
							<ResultListCell
								label="Roller/Bush Dia."
								value={entry.rollerOuterDiameter}
							/>
							
							<ResultListCell
								label="Inner Plate Width"
								value={entry.innerPlateThickness}
							/>
							<ResultListCell
								label="Outer Plate Width"
								value={entry.outerPlateThickness}
							/>
							<ResultListCell
								label="Plate Height"
								value={entry.plateHeight}
							/>
							<ResultListCell
								label="Plate Type"
								value={entry.plateType}
							/>
						</div>
					)}
				</div>
				{sketch}
			</div>
		</>
	);

}
import React, { ReactElement, useCallback, ChangeEvent, useMemo } from 'react';

import { SelectProps } from './';
import classes from './Select.module.scss';

export default (props: SelectProps): ReactElement<HTMLSelectElement> => {
	
	const { propsKey, value, handleChange } = props;

	const onChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
		handleChange(propsKey, e.currentTarget.value);
	}, [propsKey, handleChange]);

	const options = useMemo(() => props.options.map(opt => {
		return (
			<option
				key={opt.value}
				value={opt.value}
				className={classes.Option}
			>{opt.display}</option>
		);
	}), [props.options]);

	return (
		<div className={classes.Root}>
			{!!props.label && (
					<label
						className={classes.Label}
						htmlFor={props.id}
					>{props.label}</label>
				)
			}
			<div className={classes.SelectContainer}>
				<select value={value}
					className={classes.Select}
					onChange={onChange}
					id={props.id}
				>
					{options}
				</select>
			</div>
		</div>
	);

}
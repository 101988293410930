import React, { ReactElement, ChangeEvent, useCallback } from 'react';

import { FilterInputProps } from './';
import classes from './FilterInput.module.scss';
import Icon from '../Icon';
import { ReactComponent as Clear } from '../../images/close.svg';

export default (props: FilterInputProps): ReactElement<HTMLInputElement> => {
	
	const { filterKey, value, handleChange, handleClear } = props;

	const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		handleChange(filterKey, e.currentTarget.value);
	}, [filterKey, handleChange]);
	
	const onClearHandler = useCallback(() => {
		handleClear(filterKey);
	}, [filterKey, handleClear]);

	return (
		<div className={classes.Root}>
			{
				!!props.label && <label className={classes.Label}>{props.label}</label>
			}
			<div className={classes.InputContainer}>
				<input value={value} type={props.type || "text"} className={classes.Input} onChange={onChangeHandler} alt={filterKey} placeholder={props.placeholder} />
				{
					!!value &&
					<Icon icon={Clear}
						onClick={onClearHandler}
						margin={false}
						fill="white"
						iconSize="15px"
					/>
				}
			</div>
		</div>
	);

}
import React from 'react';
import { FrontPlateProps } from '.';

export default (props: FrontPlateProps) => {
	return (
		<g id="Front_Plate_Straight" onClick={props.onClick}>
			<rect x="0" y="5" width="12.7" height="15" fill="transparent"
				stroke="none"
				onClick={props.onClick}
				cursor={props.cursor || 'default'}
			/>
			<g transform="translate(1.876 29.499)" pointerEvents="none">
				<path
					fill="#ebebeb"
					stroke="#121212"
					strokeLinecap="round"
					strokeWidth="0.3"
					d="M11.096-21.285A5.075 5.254 0 006.02-16.03a5.075 5.254 0 005.076 5.254 5.075 5.254 0 00.143-.006v.006h13.008v-.008a5.075 5.254 0 00.144.008 5.075 5.254 0 005.076-5.254 5.075 5.254 0 00-5.076-5.254 5.075 5.254 0 00-.144.006v-.006H11.239v.008a5.075 5.254 0 00-.143-.008zm.326 3.008c2.967 0 2.967 4.45 0 4.45s-2.967-4.45 0-4.45zm12.7 0c2.966 0 2.966 4.45 0 4.45s-2.968-4.45 0-4.45z"
				></path>
				<path
					fill="#ebebeb"
					stroke="#121212"
					strokeLinecap="round"
					strokeWidth="0.3"
					d="M23.795-21.285a5.075 5.254 0 00-5.075 5.254 5.075 5.254 0 005.075 5.254 5.075 5.254 0 00.143-.006v.006h13.008v-.008a5.075 5.254 0 00.146.008 5.075 5.254 0 005.075-5.254 5.075 5.254 0 00-5.075-5.254 5.075 5.254 0 00-.146.006v-.006H23.938v.008a5.075 5.254 0 00-.143-.008z"
				></path>
			</g>
			<g
				fill="#f00505"
				stroke="#ff0707"
				transform="rotate(90 34.242 -3.932)"
				pointerEvents="none"
			>
				<text
					x="-51.606"
					y="-28.226"
					fill="red"
					stroke="red"
					strokeWidth="0"
					dominantBaseline="middle"
					fontFamily="Encode Sans Condensed"
					fontSize="2.3"
					textAnchor="middle"
					transform="scale(-1)"
				>
					<tspan className="text dimension">{props.height}</tspan>
				</text>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					d="M46.357 22.188v5"
				></path>
				<path
					fill="#df0c0c"
					stroke="#f31111"
					strokeWidth="0.3"
					d="M56.89 22.188v5"
				></path>
				<path
					fill="red"
					stroke="red"
					strokeWidth="0.3"
					markerEnd="url(#marker3064)"
					markerStart="url(#marker3012)"
					d="M55.896 25.988h-8.535"
				></path>
			</g>
		</g>
        
	);
}
import { HTMLProps } from 'react';

import InputModal from './InputModal';

export interface InputModalProps extends HTMLProps<HTMLDivElement> {
	show?: string;
	showProp?: string[];
	onClose?: () => void;
	heading?: string;
	variant?: number;
}

export default InputModal;
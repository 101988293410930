import React, { ReactElement } from 'react';


import { PanelProps } from './';
import classes from './Panel.module.scss';
import Button from '../Button';

export default (props: PanelProps): ReactElement<HTMLDivElement> => {
	
	const { isOpen } = props;

  const rootClass = [classes.Root];
  const containerClass = [classes.Container];

  if (isOpen) {
    rootClass.push(classes.Open);
    containerClass.push(classes.Open);
  }

  return (
    <div className={rootClass.join(' ')}>
      <div className={containerClass.join(' ')}>
        {!!props.title && (
          <div className={classes.Title} role="heading">{props.title}</div>
        )}
        <div className={classes.Content}>
          {props.children}
        </div>
        {
      
          props.onClose && (
            <Button type="confirm" onClick={props.onClose} className={classes.CloseButton}>
              APPLY AND CLOSE
            </Button>
          )
        }
      </div>
    </div>
  );

}
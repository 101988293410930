import React from 'react';
import { ComponentSketchProps } from '.';

export default (props: ComponentSketchProps) => {
	return (
	
		<g id="g1639" transform="translate(28.123 .29)">
			<path
				id="path1627"
				fill="none"
				stroke="#000"
				strokeWidth="0.3"
				d="M32.122 8.82V-1.279"
				markerStart="url(#edit-line-start)"
			></path>
			<g
				id="g1637"
				stroke="#121212"
				strokeLinejoin="round"
				transform="translate(12.891 .098)"
				onClick={props.onClick} cursor="pointer"
			>
				<circle
					id="circle1629"
					cx="19.13"
					cy="-4.921"
					r="3.5"
					fill="#ebebeb"
					strokeWidth="0.3"
				></circle>
				<g
					id="g1635"
					strokeWidth="0.457"
					transform="rotate(39.769 6.36 34.036) scale(.65592)"
				>
					<path
						id="rect1631"
						d="M-13.878 -9.277H-12.878V-4.276999999999999H-13.878z"
					></path>
					<path
						id="path1633"
						d="M-13.378-2.663l-.5-.866h1l-.25.433z"
					></path>
				</g>
			</g>
		</g>
	);
}
import React, { ReactElement, useMemo, useCallback } from 'react';

import { FiltersPanelProps } from '.';
import classes from './FiltersPanel.module.scss';
import Button from '../Button';
import Icon from '../Icon';
import FilterInput from '../FilterInput';

import { ReactComponent as Clear } from '../../images/close.svg';
import Panel from '../Panel';
import Select from '../Select';
import {
  plateTypeOptions,
  rollerTypeOptions,
  pinTypeOptions,
  accuracyOptions,
  chainTypeOptions,
} from '../../hooks/useFilter';
import { getLengthUnits, getForceUnits } from '../../hooks/usePreferences';

export default (props: FiltersPanelProps): ReactElement<HTMLDivElement> => {
  
  
  const { state, dispatch, resultCount, units, ...rest } = props;
  
  const lengthUnits = useMemo(() => getLengthUnits(units), [units]);
  const forceUnits = useMemo(() => getForceUnits(units), [units]);

  const handleFilterChange = useCallback((key: string, value: string) => {
    dispatch({
			type: 'filter',
			key,
			value,
		});
  }, [dispatch]);
  
  const handleFilterClear = useCallback((key: string) => {
    dispatch({
			type: 'clear',
			key,
		});
  }, [dispatch]);

	return (
		<Panel {...rest} title="Filters">
			<div className={classes.Count}>
        {resultCount} results found
      </div>
      <Button onClick={() => dispatch({ type: 'clearAll' })}>
        <span className={classes.Label}>Clear all filters</span><Icon icon={Clear} margin={false} iconSize="12px" />
      </Button>
      <Select label="Search Accuracy"
        value={state.accuracy.value}
        propsKey="accuracy"
        handleChange={handleFilterChange}
        options={accuracyOptions}
      />
      <FilterInput label={`Pitch (${lengthUnits})`}
        value={state.pitch.value}
        filterKey="pitch"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Inside Width (${lengthUnits})`}
        value={state.insideWidth.value}
        filterKey="insideWidth"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Roller / Bush Dia. (${lengthUnits})`}
        value={state.rollerOuterDiameter.value}
        filterKey="rollerOuterDiameter"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Pin Outer Diameter (${lengthUnits})`}
        value={state.pinOuterDiameter.value}
        filterKey="pinOuterDiameter"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Pin Length (${lengthUnits})`}
        value={state.pinLength.value}
        filterKey="pinLength"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Inner Plate Width (${lengthUnits})`}
        value={state.innerPlateThickness.value}
        filterKey="innerPlateThickness"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Outer Plate Width (${lengthUnits})`}
        value={state.outerPlateThickness.value}
        filterKey="outerPlateThickness"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Plate Height (${lengthUnits})`}
        value={state.plateHeight.value}
        filterKey="plateHeight"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <FilterInput label={`Tensile Strength (${forceUnits})`}
        value={state.tensileStrengthNewtons.value}
        filterKey="tensileStrengthNewtons"
        handleChange={handleFilterChange}
        handleClear={handleFilterClear}
      />
      <Select label="Chain Type"
        value={state.chainType.value}
        propsKey="chainType"
        handleChange={handleFilterChange}
        options={chainTypeOptions}
      />
      <Select label="Plate Type"
        value={state.plateType.value}
        propsKey="plateType"
        handleChange={handleFilterChange}
        options={plateTypeOptions}
      />
      <Select label="Roller Type"
        value={state.rollerType.value}
        propsKey="rollerType"
        handleChange={handleFilterChange}
        options={rollerTypeOptions}
      />
      <Select label="Pin Type"
        value={state.pinType.value}
        propsKey="pinType"
        handleChange={handleFilterChange}
        options={pinTypeOptions}
      />
		</Panel>
	);

}
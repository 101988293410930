import { useCallback, useState, useEffect, Dispatch } from 'react';

import useFilter, { FilterAction, FilterState, FilterType, FilterValue } from './useFilter';
import { Entry, EntryServer } from '.';
import { PreferencesState, DecimalSeparator, Units } from './usePreferences';

type Collection = { name: string };

export interface CatalogueEntry extends Entry {
	[key: string]: string | number | number[] | Collection | boolean;
	collection: Collection
}

export interface CatalogueEntryWithIndex extends CatalogueEntry {
	index: number;
}

export interface CatalogueEntryServer extends EntryServer {
	[key: string]: string | number | number[] | Collection | boolean;
	collection: Collection
}

export interface CatalogueEntryDisplay {
	collection: Collection

	innerPlateThickness: string;
	outerPlateThickness: string;
	insideWidth: string;
	name: string;
	description: string;
	pinOuterDiameter: string;
	pinLength: string;
	plateHeight: string;
	rollerOuterDiameter: string;
	tensileStrengthNewtons: string;
	// waistRatio?: string;
	pitchInner: string[];
	pitchOuter: string[];
	plateType: string;
	pinType: string;
	gearingType: string;
}

interface CatalogueStorage {
	lastUpdated: number;
	entries: CatalogueEntry[];
	'format-version': string;
}

type ToleranceBand = {
	min: number;
	max: number;
};

// const UPDATE_FREQ_DAYS = 1;
const CONVERSION_FACTOR = {
	force: 0.2248,
	length: 25.4,
};


export default (preferencesState: PreferencesState): [
	CatalogueEntryDisplay[],
	FilterState,
	Dispatch<FilterAction>,
] => {

	const [catalogue, setCatalogue] = useState<CatalogueStorage>({
		lastUpdated: -1,
		entries: [],
		'format-version': '',
	});

	const [filteredDisplayCatalogue, setFilteredDisplayCatalogue] =
		useState<CatalogueEntryDisplay[]>([]);
	
	const [displayCatalogue, setDisplayCatalogue] =
		useState<CatalogueEntryDisplay[]>([]);
	
	const [filter, filterDispatch] = useFilter();
	

	const handleSetCatalogue = useCallback(
		(
			newCatalogueEntries: {
				catalogue: CatalogueEntryServer[],
				keys: string[],
			}, lastUpdated: number, formatVersion: string
		): void => {

		// Map keys back to data objects
		for (let entry of newCatalogueEntries.catalogue) {
			for (let key in entry) {
				const index = Number(key);
				const properKey = newCatalogueEntries.keys[index];
				entry[properKey] = entry[key];
				delete entry[key];
			}	
		}
			
		const newCatalogue = {
			lastUpdated,
			entries: newCatalogueEntries.catalogue,
			'format-version': formatVersion,
		};
		setCatalogue(newCatalogue);

		localStorage.setItem('catalogue', JSON.stringify(newCatalogue));
		}, []);
	
	useEffect(() => {

		const run = async () => {

			const data = await fetch(`${process.env.REACT_APP_DATA_URL}/${process.env.REACT_APP_MANIFEST_FILE}`, {
				cache: 'no-cache',
				method: 'GET',
			})

			const server = await data.json();
			
			const lastUpdated = server.created;
			const formatVersion = server['format-version'];

			const catalogueInStorage: CatalogueStorage =
				JSON.parse(localStorage.getItem('catalogue') || '{}');
			
			const isMostRecent = !!catalogueInStorage.lastUpdated &&
				lastUpdated - catalogueInStorage.lastUpdated <= 0;
			
			const isSameVersion = !!catalogueInStorage['format-version'] && catalogueInStorage['format-version'] === formatVersion;
			
			const updateRequired = !isMostRecent || !isSameVersion
			
	
			if (!updateRequired) {
				console.log('setting catalogue from store');
				setCatalogue(catalogueInStorage);
			} else {

				console.log('catalogue requires network data');
				const data = await fetch(`${process.env.REACT_APP_DATA_URL}/${process.env.REACT_APP_CATALOGUE_FILE}`, {
          cache: 'no-cache',
          method: 'GET',
        })

        const result = await data.json();

				handleSetCatalogue(result, lastUpdated, formatVersion);
			}
		}

		run();
		
	}, [handleSetCatalogue]);


	// Update the full display catalogue
	useEffect(() => {

		const newDisplayCatalogue = convertToStringValues(
			catalogue.entries,
			preferencesState.decimalSeparator,
		  preferencesState.units,
		);
		
		setDisplayCatalogue(newDisplayCatalogue);

	}, [catalogue, preferencesState.decimalSeparator, preferencesState.units]);

	
	useEffect(() => {

		// If we are switching to show image, write pitch to both inner and outer pitch, otherwise clear all pitch filters

		if (preferencesState.displayImageInput) {
			filterDispatch({
				key: 'pitchInner',
				type: 'filter',
				value: filter.pitch.value,
			});
			filterDispatch({
				key: 'pitchOuter',
				type: 'filter',
				value: filter.pitch.value,
			});
		} else {
			filterDispatch({
				key: 'pitch',
				type: 'clear',
			});
			filterDispatch({
				key: 'pitchInner',
				type: 'clear',
			});
			filterDispatch({
				key: 'pitchOuter',
				type: 'clear',
			});
		}
		
	}, [preferencesState.displayImageInput, filterDispatch, filter.pitch.value]);
	

	const filterOutPartialDataEntries = (entries: CatalogueEntryWithIndex[]): CatalogueEntryWithIndex[] => {
		
		// Keys that are allowed to be blank/missing
		const ignoreKeys = [
			'gearingType',
			'pinType',
			'plateType',
		];

		return entries.filter(entry => {
			for (let key in entry) {

				// Do not filter out on the basis of this key if it is an ignored key
				if (ignoreKeys.includes(key)) {
					return true;
				}

				const value = entry[key];
				const hasMissingData =
					value === 0 ||
					value === -1 ||
					value === '' ||
					(value instanceof Array && value.length === 0);
				
				if (hasMissingData) {
					// If there are any missing fields, filter this entry out
					return false;
				}
			}

			// If all fields contain a meaningful value, keep it
			return true;
		});
	}

	// Update the filtered display catalogue
	useEffect(() => {

		// Return early if the display catalogue is not yet inititalised
		if (displayCatalogue.length === 0) {
			setFilteredDisplayCatalogue([]);
			return;
		}

		let results: CatalogueEntryWithIndex[] =
			catalogue.entries
				.map((result, index) => ({ ...result, index }));
		
		if (preferencesState.includeResultsWithPartialData === 'false') {
			results = filterOutPartialDataEntries(results);
		}
		

		const accuracyBand = {
			min: 1 - Number(filter.accuracy.value),
			max: Number(filter.accuracy.value) + 1,
		};


		for (let key in filter) {
			
			const conversionFactor = getFilterConversionFactor(
				preferencesState.units,
				key,
			);
			
			const value = getValue(filter[key], conversionFactor);

			// Ignore empty fields and accuracy 
			// Tolerance is used for creating the search band rather than checking fields in the database
			if (!value || value === [] || key === 'accuracy') {
				continue;
			}

			// If pitch is set, ignore pitchInner and pitchOuter filters
			if (!!filter.pitch.value && (key === 'pitchInner' || key === 'pitchOuter')) {
				continue;
			}

			const predicate =
				getFilterPredicate(value, filter[key].type, key, accuracyBand);
						
			results = results.filter(entry => predicate(entry));
		}


		const catalogueToDisplay = results
			.map(result => displayCatalogue[result.index]);

		setFilteredDisplayCatalogue(catalogueToDisplay);
			
	}, [
		filter,
		catalogue,
		preferencesState.decimalSeparator,
		preferencesState.units,
		displayCatalogue,
		preferencesState.includeResultsWithPartialData
	]);
	
	return [
		filteredDisplayCatalogue,
		filter,
		filterDispatch,
	];
}

function getValue(filter: FilterValue, conversionFactor: number): string | number | number[] {
	let value;
	
	switch (filter.type) {
		case 'number': case 'number[]':
			value = Number(filter.value.replace(',', '.')) * conversionFactor;
			break;
		default: value = filter.value; break;
	}

	return value;
}

function findRangeMatchInNumArray(numArray: number[], value: number, accuracyBand: ToleranceBand) {
	return numArray.some(entryValue => {
		return entryValue <= accuracyBand.max * value &&
			entryValue >= accuracyBand.min * value
	});
}

function getFilterPredicate(
	value: string | number | string[] | number[],
	type: FilterType,
	key: string,
	accuracyBand: ToleranceBand,
): ((entry: CatalogueEntry) => boolean) {

	if (type === 'number') {
		return (entry: CatalogueEntry) => {
			return (
				entry[key] <= accuracyBand.max * (value as number) && 
				entry[key] >= accuracyBand.min * (value as number) 
			) || entry[key] < 0;
		}	
	}

	const isNominalPitch = key === 'pitch';

	if (type === 'number[]' && !isNominalPitch) {
		return (entry: CatalogueEntry) => findRangeMatchInNumArray(entry[key] as number[], value as number, accuracyBand); 
	}

	// Handle plateType in a specific way
	if (isNominalPitch) {
		return (entry: CatalogueEntry) => {

			const matchPitchInner = findRangeMatchInNumArray(
				entry.pitchInner, value as number, accuracyBand
			);

			// Don't bother checking outer if we already have a match on inner
			if (matchPitchInner) {
				return true;
			}
			
			return !!findRangeMatchInNumArray(
				entry.pitchOuter, value as number, accuracyBand
			); 
		};
	}


	// Handle plateType in a specific way
	if (key === 'chainType') {
		return (entry: CatalogueEntry) => {
			
			// Do not filter out if we don't have info
			if (!entry.chainType) {
				return true;
			}

			switch (value.toString()) {
				case 'engineering_class':
					return entry.chainType === 'EngineeringClassChains';
				case 'cvc':
					return entry.chainType === 'CvcChains';
				case 'transmission':
					return entry.chainType === 'TransmissionChains';
				default:
					return true;
			};
		};
	}

	// Handle plateType in a specific way
	if (key === 'plateType') {
		return (entry: CatalogueEntry) => {
			
			// Do not filter out if we don't have info
			if (!entry.plateType) {
				return true;
			}

			switch (value.toString()) {
				case 'waisted':
					return entry.plateType === 'Waisted';
				case 'straight':
					return entry.plateType === 'Straight sided';
				case 'cropped':
					return entry.plateType === 'Cropped';
				default: return true;
			};
		};
	}


	// Handle rollerType in a specific way
	if (key === 'rollerType') {
		return (entry: CatalogueEntry) => {

			// Do not filter out if we don't have info
			if (!entry.gearingType) {
				return true;
			}

			switch (value.toString().toUpperCase()) {
				case 'PLAIN':
					return entry.gearingType === 'roller' || entry.gearingType === 'large roller';
				case 'NONE':
					return entry.gearingType === 'bush';
				case 'FLANGED':
					return entry.gearingType === 'flanged roller';
				default: return true;
			};
		};
	}


	// Handle pin type in a specific way
	if (key === 'pinType') {
		return (entry: CatalogueEntry) => {

			// Do not filter out if we don't have info
			if (!entry.pinType) {
				return true;
			}

			switch (value.toString().toUpperCase()) {
				case 'SOLID':
					return entry.pinType === 'pin';
				case 'SOLID_FLAT':
					return entry.pinType === 'flatted pin';
				case 'HOLLOW':
					return entry.pinType === 'hollow pin';
				default: return true;
			};
		};
	}

	return (entry: CatalogueEntry) => value === entry[key];
}

function convertToStringValues(catalogue: CatalogueEntry[], decimalSeparator: DecimalSeparator, units: Units): CatalogueEntryDisplay[] {

	const converted = catalogue.map(entry => {

		const out: any = {};

		for (let key in entry) {
			if (typeof entry[key] === 'number') {
				// if (key === 'waistRatio') {
				// 	out.waistRatio = entry.waistRatio.toString();
				// } else {
					out[key] =
						convertValue(entry[key] as number, key, decimalSeparator, units);
				// }
			} else if (Array.isArray(entry[key])) {
				out[key] =
					convertArrayValue(
						entry[key] as number[], key, decimalSeparator, units
					);
			} else {
				out[key] = entry[key];
			}
		}

		return out;
	});

	return converted;
}

function convertValue(
	value: number,
	key: string,
	decimalSeparator: DecimalSeparator,
	units: Units
): string {

	if (value === -1) {
		return '-';
	}

	const convertedValue =
		value * getDisplayConversionFactor(units, key);

	const decimalPlaces = isForceValue(key) ? 0 : 2;
	const convertedValueStr =
		convertedValue.toFixed(decimalPlaces).replace('.', decimalSeparator)

	return `${convertedValueStr} ${getUnitSymbol(key, units)}`;
}

function getUnitSymbol(key: string, units: Units): string {
	
	const isMetricUnits = isMetric(units);

	if (isForceValue(key)) {
		return isMetricUnits ? 'N' : 'lbf';
	}

	return isMetricUnits ? 'mm' : 'in.';
}

function isForceValue(key: string): boolean {
	return key === 'tensileStrengthNewtons';
}

function isMetric(units: Units): boolean {
	return units === 'metric';
}

function convertArrayValue(
	value: number[],
	key: string,
	decimalSeparator: DecimalSeparator,
	units: Units
): string[] {
	return value
		.map((v: number) => convertValue(v, key, decimalSeparator, units));
}

function getFilterConversionFactor(units: Units, key: string): number {

	// Filtering is always done on the metric catalogue, so convert supplied inputs (in selected units) to metric

	if (isMetric(units)) {
		return 1;
	};
	
	return isForceValue(key) ?
		1 / CONVERSION_FACTOR.force :
		CONVERSION_FACTOR.length;
}

function getDisplayConversionFactor(units: Units, key: string): number {

	// Display catalogue is always derived from the metric catalogue, so convert metric catalogue values to selected units

	if (isMetric(units)) {
		return 1;
	};
	
	return isForceValue(key) ?
		CONVERSION_FACTOR.force :
		1 / CONVERSION_FACTOR.length;
}
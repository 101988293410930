import SettingsPanel from './SettingsPanel';
import { InputsPanelProps } from '../InputsPanel';

import { PreferencesState, PreferencesAction }
	from '../../hooks/usePreferences';

export interface SettingsPanelProps extends
	InputsPanelProps<PreferencesState, PreferencesAction> {

}

export default SettingsPanel;
import { HTMLProps } from 'react';

import ResultList from './ResultList';
import { CatalogueEntryDisplay } from '../../hooks/useCatalogue';

export interface ResultListProps extends HTMLProps<HTMLDivElement> {
	entries: CatalogueEntryDisplay[];
	displayImage: boolean;
}

export default ResultList;
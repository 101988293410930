import React, { ReactElement, useMemo } from 'react';

import { SketchProps } from './';
import classes from './Sketch.module.scss';
import FrontPlateCropped from './Parts/FrontPlateCropped';
import FrontRollerFlanged from './Parts/FrontRollerFlanged';
import FrontRollerLarge from './Parts/FrontRollerLarge';
import FrontPlateWaisted from './Parts/FrontPlateWaisted';
import FrontGeneral from './Parts/FrontGeneral';
import FrontPinRound from './Parts/FrontPinRound';
import FrontPinFlat from './Parts/FrontPinFlat';
import RightPinFlat from './Parts/RightPinFlat';
import RightPinRound from './Parts/RightPinRound';
import RightGeneral from './Parts/RightGeneral';
import RightRollerFlanged from './Parts/RightRollerFlanged';
import RightRollerLarge from './Parts/RightRollerLarge';
import RightRollerNone from './Parts/RightRollerNone';
import RightRollerStandard from './Parts/RightRollerStandard';
import FrontPlateStraight from './Parts/FrontPlateStraight';

export default (props: SketchProps): ReactElement<SVGSVGElement> => {
	
	const {
		dimensions,
	} = props;

	const {
		innerPlateThickness,
		insideWidth,
		outerPlateThickness,
		pinLength,
		plateHeight,
		rollerOuterDiameter,
    plateType,
    pinType,
    gearingType,
	} = dimensions;

	const flats = pinType === 'flatted pin';
	const flangedRoller = gearingType === 'flanged roller';
	const largeRoller = gearingType === 'large roller'
	const bushChain = gearingType === 'bush'
	
	
	const frontPlate = useMemo(() => {

    const conveyor = plateType === 'Cropped';
		const waisted = plateType === 'Waisted';

		if (conveyor) {
			return <FrontPlateCropped height={plateHeight} />
		} else {
			return waisted ?
				<FrontPlateWaisted height={plateHeight} /> :
				<FrontPlateStraight height={plateHeight} />
		}
	}, [plateHeight, plateType]);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.93 31.555"
			className={classes.View}
		>
      <defs>
        <marker id="marker19612" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker19452" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18574" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker18426" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18244" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker18108" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker17382" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker17246" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker15525" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker15401" orient="auto" overflow="visible">
          <path
            fill="#f31111"
            fillRule="evenodd"
            stroke="#f31111"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker11455" orient="auto" overflow="visible">
          <path
            fill="#ff0707"
            fillRule="evenodd"
            stroke="#ff0707"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker11331" orient="auto" overflow="visible">
          <path
            fill="#ff0707"
            fillRule="evenodd"
            stroke="#ff0707"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker11141" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker11029" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker10336" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker10236" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker8906" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker8806" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker4846" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker4770" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
        <marker id="marker3064" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M2.308 0l-3.46 2v-4z"
          ></path>
        </marker>
        <marker id="marker3012" orient="auto" overflow="visible">
          <path
            fill="red"
            fillRule="evenodd"
            stroke="red"
            strokeWidth=".4pt"
            d="M-2.308 0l3.46-2v4z"
          ></path>
        </marker>
      </defs>
      <g id="Front" transform="translate(0 .316)">
        <g id="Front_Roller">
					{flangedRoller && <FrontRollerFlanged />}
					{largeRoller && <FrontRollerLarge />}
        </g>
				<g id="Front_Plate">
					
					{frontPlate}
					
        </g>
        
				<FrontGeneral pitchInner="Pitch Inner" pitchOuter="Pitch Outer" />
				
        <g id="Front_Pin">
					{flats ? <FrontPinFlat /> : <FrontPinRound />}
        </g>
        <g id="Front_Axes">
          <g id="#g22628">
            <path
              fill="#ebebeb"
              stroke="#000"
              strokeDasharray="1.20000005, 0.60000002, 0.30000001, 0.60000002"
              strokeWidth="0.3"
              d="M25.902 9.211v8.45m-4.225-4.225h8.45M13.202 9.21v8.45m-4.225-4.224h8.45M38.602 9.211v8.45m-4.225-4.225h8.45"
              className="axis"
            ></path>
          </g>
        </g>
      </g>
      <g id="Right" transform="translate(0 .316)">
        <g id="Right_Pin">
					{flats && <RightPinFlat length={pinLength} />}
					{!flats && <RightPinRound length={pinLength} />}
        </g>
				<RightGeneral
					innerPlateThickness={innerPlateThickness}
					outerPlateThickness={outerPlateThickness}
					insideWidth={insideWidth}
				/>
        <g id="Right_Roller">
					{flangedRoller &&
						<RightRollerFlanged diameter={rollerOuterDiameter} />}
					{largeRoller &&
						<RightRollerLarge diameter={rollerOuterDiameter} />}
					{bushChain &&
						<RightRollerNone diameter={rollerOuterDiameter} />}
					{!flangedRoller && !largeRoller && !bushChain &&
						<RightRollerStandard diameter={rollerOuterDiameter} />}
        </g>
        <g id="Right_Axes">
          <path
            fill="#ebebeb"
            stroke="#000"
            strokeDasharray="1.20000005, 0.60000002, 0.30000001, 0.60000002"
            strokeWidth="0.3"
            d="M60.238 5.944v15m-9.5-7.5h19"
            className="axis"
          ></path>
        </g>
      </g>
    </svg>
	);

}

import { HTMLProps } from 'react';

import Sketch from './InputSketch';

import { FilterState } from '../../../hooks/useFilter';

export interface InputSketchProps extends HTMLProps<SVGSVGElement> {
	state: FilterState;
	openModal: (key: string, heading: string) => void;
	units: string;
}

export default Sketch;
import React, { ReactElement, useMemo } from 'react';

import { ButtonProps } from './';
import classes from './Button.module.scss';
import Icon from '../Icon';


export default (props: ButtonProps): ReactElement<HTMLButtonElement> => {
	
	const buttonClass = useMemo(() => {
		switch (props.type) {
			case 'cancel': return 'Cancel';
			case 'confirm': return 'Confirm';
			default: return 'Default'
		}
	}, [props.type]);

	const combinedClass = [classes.Root, classes[buttonClass], props.className];

	if (!!props.inline) {
		combinedClass.push(classes.Inline);
	}

	return (
		<button className={combinedClass.join(' ')}
			onClick={props.onClick}
		>
			{props.children}
			{!!props.icon && <Icon {...props.icon} />}
		</button>
	);

}
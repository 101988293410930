import { HTMLProps } from 'react';

import FilterInput from './FilterInput';

export interface FilterInputProps extends HTMLProps<HTMLInputElement> {
	filterKey: string;
	handleChange: (key: string, value: string) => void;
	handleClear: (key: string) => void;
}

export default FilterInput;
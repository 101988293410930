import { HTMLProps } from 'react';

import ResultListEntry from './ResultListEntry';
import { CatalogueEntryDisplay } from '../../hooks/useCatalogue';

export interface ResultListEntryProps extends HTMLProps<HTMLDivElement> {
	entry: CatalogueEntryDisplay;
	displayImage: boolean;
}

export default ResultListEntry;
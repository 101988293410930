import React from 'react';
import { RightRollerProps } from '.';

export default (props: RightRollerProps) => {
	return (
		<g id="Right_Roller_Standard">
			<g id="#g22217">
				<rect x="63" y="7" width="12.7" height="12" fill="transparent" stroke="none" onClick={props.onClick} cursor={props.cursor || 'default'} />
				<path
					fill="#ebebeb"
					stroke="#000"
					strokeLinecap="round"
					strokeWidth="0.3"
					d="M55.069 1.29H63.163000000000004V10.733H55.069z"
					transform="translate(1.122 7.433)"
					pointerEvents="none"
				></path>
				<g
					fill="#df0c0c"
					stroke="#f31111"
					transform="matrix(0 1 1 0 49.812 -38.562)"
					pointerEvents="none"
				>
					<text
						x="-51.615"
						y="24.472"
						fill="red"
						stroke="red"
						strokeWidth="0"
						dominantBaseline="middle"
						fontFamily="Encode Sans Condensed"
						fontSize="2.3"
						textAnchor="middle"
						transform="scale(-1 1)"
					>
						<tspan className="text dimension">{props.diameter}</tspan>
					</text>
					<path
						fill="red"
						stroke="red"
						strokeWidth="0.3"
						d="M47.28 15.31v8M56.68 15.31v8"
					></path>
					<path
						fill="red"
						stroke="red"
						strokeWidth="0.3"
						markerEnd="url(#marker10336)"
						markerStart="url(#marker10236)"
						d="M55.766 22.188h-7.553"
					></path>
				</g>
			</g>
		</g>
	);
}